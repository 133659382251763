import React from 'react';
import Box from '@mui/material/Box';
import firstStepImage from 'images/stepsOfInvestor/1.png';
import secondStepImage from 'images/stepsOfInvestor/2.png';
import thirdStepImage from 'images/stepsOfInvestor/3.png';
import forthStepImage from 'images/stepsOfInvestor/4.png';
import fifthStepImage from 'images/stepsOfInvestor/5.png';
import sixthStepImage from 'images/stepsOfInvestor/6.png';
// import walletConnectPic from 'images/stepsOfInvestor/walletconnect.png';
import { BOOK_CALL_LINK } from 'links';
// import { Illustration } from './modules';
import * as Styles from './Styles';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';

const picList = [
  {
    id: 1,
    name: '1',
    src: firstStepImage,
    alt: 'Illustration of investor creating profile on Coreestate investment platform',
    description: 'Book call',
  },
  {
    id: 2,
    name: '2',
    src: secondStepImage,
    alt: 'Email icon illustration representing email verification after registration on real estate tokenization platform. ',
    description: 'Verify your email',
  },
  {
    id: 3,
    name: '3',
    src: thirdStepImage,
    alt: 'Illustration of people under maginfier glass, representing know your customer KYC process related to onboarding in real estate investment platform.',
    description: 'KYC (Know Your Customer) verification process',
  },
  {
    id: 4,
    name: '4',
    src: forthStepImage,
    alt: 'Illustration representing administrative review of investor profile to ensure safe platform environment.',
    description: 'Submit your profile to be reviewed',
  },
  {
    id: 5,
    name: '5',
    src: fifthStepImage,
    alt: 'Thumbs up illustration with approved investor documentation allowing to invest in real estate digital shares',
    description: 'Approved',
  },
  {
    id: 6,
    name: '6',
    src: sixthStepImage,
    alt: 'Illustration of cart with Coreestate digital share bought by real estate investor. ',
    description: 'Purchase Digitized Real Estate',
    // illustrationForDesc: walletConnectPic,
  },
];

const TradeTokens = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <Styles.ContentBox>
      <Styles.ContentContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Styles.Title component="h1" variant="h4">
          There are only 6 steps to become an investor and owner of rental real
          estate!
        </Styles.Title>

        <Styles.CardGrid container spacing={2} rowSpacing={2}>
          {picList.map((card, index, wholeList) => {
            const lastCard: boolean = wholeList.length - 1 === index;
            const sidePadding = '2.8em';
            const verticalPadding = '1.5em';

            return (
              <Styles.CardGridContainer
                item
                key={card.id}
                xs={12}
                sm={6}
                md={4}
              >
                <Styles.Card
                  elevation={0}
                  sx={{
                    padding: `${verticalPadding} ${sidePadding}`,
                    paddingLeft: '1.7em',
                    paddingRight: 0,
                  }}
                >
                  <Styles.PictureBox>
                    <Styles.Picture
                      src={card.src}
                      component={'img'}
                      alt={card.alt}
                      height={1}
                      width={1}
                    />
                  </Styles.PictureBox>
                  <Box textAlign="center">
                    <Styles.CardNumeration>{card.name}</Styles.CardNumeration>
                    <Styles.CardDescription>
                      <p
                        style={{
                          display: 'inline',
                          marginLeft: lastCard ? '-1.2em' : 0,
                        }}
                      >
                        {card.description}
                      </p>
                      {/*  {!!card?.illustrationForDesc &&
                        Illustration(card?.illustrationForDesc, 27)} */}
                    </Styles.CardDescription>
                  </Box>
                  <Styles.PointingTriangle />
                </Styles.Card>
              </Styles.CardGridContainer>
            );
          })}
        </Styles.CardGrid>

        {/* <Styles.ActionButton
          href={BOOK_CALL_LINK}
          target="_blank"
          variant="contained"
          color="alternate"
        >
          Book a call
        </Styles.ActionButton> */}
      </Styles.ContentContainer>
    </Styles.ContentBox>
  );
};

export default TradeTokens;
